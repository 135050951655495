<template>
  <div style="width: 100%; height: 100%;">
    <GmapMap
      :center="center"
      :zoom="13"
      map-type-id="terrain"
      style="width: 100%; height: 100%;"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        @click="onClickedMarker(m, index)"
      >
        <GmapInfoWindow
          :opened.sync="m.ifw"
          :options="{
            pixelOffset: { width: 0, height: 0 },
            content: m.template,
          }"
          @closeclick="m.ifw = false"
        />
      </GmapMarker>
    </GmapMap>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      center: { lat: 42.278767, lng: -71.420351 },
      markers: [],
    };
  },
  methods: {
    ...mapActions("client", {
      loadClientMap: "loadClientMap",
    }),
    onClickedMarker(m, index) {
      // this.center = m.position;
      this.markers = this.markers.map((m) => {
        m.ifw = false;
        return m;
      });
      this.markers[index].ifw = true;
    },
  },
  mounted() {
    this.loadClientMap()
      .then((res) => {
        this.markers = res.markers;
        this.center = res.center;
        console.log(this.markers);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  },
};
</script>
